/**
 * WhatsUp Application API
 * This is the WhatsUp RESTful service using OpenAPI 3.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MessageResponse } from './messageResponse';


/**
 * POJO that represents a incident.
 */
export interface IncidentResponse { 
    messages?: Array<MessageResponse>;
    /**
     * The type of the incident
     */
    issueType: IncidentResponse.IssueTypeEnum;
    /**
     * Date of creation
     */
    createdAt: string;
    /**
     * Date of last state change
     */
    lastStateChange: string;
    /**
     * The id of the user, who created this incident.
     */
    issuerUserId: string;
    /**
     * The id of the user, the incident is assigned to. Can be null when the state is OPEN
     */
    assigneeUserId?: string;
    id: string;
    /**
     * State of the incident
     */
    state: IncidentResponse.StateEnum;
    /**
     * The id of the service, which has an incident.
     */
    serviceId: string;
    /**
     * Number of the incident in the VW ticket system.
     */
    incidentNumber: string;
    /**
     * Number of the change in the VW ticket system.
     */
    changeNumber: string;
}
export namespace IncidentResponse {
    export type IssueTypeEnum = 'FAILURE' | 'PARTIAL_FAILURE' | 'REMEDIED' | 'RELEASE' | 'ALARMED';
    export const IssueTypeEnum = {
        FAILURE: 'FAILURE' as IssueTypeEnum,
        PARTIALFAILURE: 'PARTIAL_FAILURE' as IssueTypeEnum,
        REMEDIED: 'REMEDIED' as IssueTypeEnum,
        RELEASE: 'RELEASE' as IssueTypeEnum,
        ALARMED: 'ALARMED' as IssueTypeEnum
    };
    export type StateEnum = 'OPEN' | 'ASSIGNED' | 'IN_PROGRESS' | 'DONE';
    export const StateEnum = {
        OPEN: 'OPEN' as StateEnum,
        ASSIGNED: 'ASSIGNED' as StateEnum,
        INPROGRESS: 'IN_PROGRESS' as StateEnum,
        DONE: 'DONE' as StateEnum
    };
}


