import {Injectable} from '@angular/core';
import {map, publishLast, refCount} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../config/app-config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public configuration: AppConfig;
  private fallbackConfig = {
    apiUrl: environment.apiUrlFallback,
    authUrl: environment.authUrlFallback,
    clientId: environment.clientIdFallback,
    version: environment.version,
  };

  constructor(private http: HttpClient) {
  }

  initializeApp(): Promise<any> {

    return new Promise(
        (resolve) => {
          this.http.get(environment.envConfigUrl, {
            responseType: 'json'
          })
          .pipe(
              publishLast(),
              refCount(),
              map(value => value as AppConfig))
          .toPromise()
          .then(response => {
                this.configuration = response;
                resolve();
              }
          )
          .catch(reason => {
            this.configuration = this.fallbackConfig;
            resolve();
          });
        }
    );
  }


  getApiUrl() {
    return this.configuration?.apiUrl;
  }

  getClientId() {
    return this.configuration?.clientId;
  }

  getAuthUrl() {
    return this.configuration?.authUrl;
  }

}
