/**
 * WhatsUp Application API
 * This is the WhatsUp RESTful service using OpenAPI 3.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * POJO that represents an incident, that should be created.
 */
export interface IncidentCreateRequest { 
    /**
     * The type of the incident
     */
    issueType: IncidentCreateRequest.IssueTypeEnum;
    /**
     * The id of the service, which has an incident.
     */
    serviceId: string;
    /**
     * Number of the incident in the VW ticket system.
     */
    incidentNumber: string;
    /**
     * Number of the change in the VW ticket system.
     */
    changeNumber: string;
    /**
     * Content of the mail, which will be sent as notification to the subscribers.
     */
    mailBody: string;
    /**
     * Subject of the mail, which will be sent as notification to the subscribers.
     */
    mailSubject: string;
    /**
     * Content of the SMS, which will be sent as notification to the subscribers.
     */
    smsContent: string;
    /**
     * Content of the push notification, which will be sent as notification to the subscribers.
     */
    pushContent: string;
}
export namespace IncidentCreateRequest {
    export type IssueTypeEnum = 'FAILURE' | 'PARTIAL_FAILURE' | 'REMEDIED' | 'RELEASE' | 'ALARMED';
    export const IssueTypeEnum = {
        FAILURE: 'FAILURE' as IssueTypeEnum,
        PARTIALFAILURE: 'PARTIAL_FAILURE' as IssueTypeEnum,
        REMEDIED: 'REMEDIED' as IssueTypeEnum,
        RELEASE: 'RELEASE' as IssueTypeEnum,
        ALARMED: 'ALARMED' as IssueTypeEnum
    };
}


