/**
 * WhatsUp Application API
 * This is the WhatsUp RESTful service using OpenAPI 3.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a relation of a user to a service. This means, a user can either be in the role of a MANAGER to a service or in the role of a SUBSCRIBER, or even in both roles.
 */
export interface ServiceRelation { 
    /**
     * This is the id of the service, the user has a relation to.
     */
    serviceId: string;
    serviceRoles?: Array<ServiceRelation.ServiceRolesEnum>;
}
export namespace ServiceRelation {
    export type ServiceRolesEnum = 'MANAGER' | 'SUBSCRIBER';
    export const ServiceRolesEnum = {
        MANAGER: 'MANAGER' as ServiceRolesEnum,
        SUBSCRIBER: 'SUBSCRIBER' as ServiceRolesEnum
    };
}


