export class Rights {
  private roles: Array<'ADMINISTRATOR' | 'MANAGER' | 'SUBSCRIBER'>;
  canAddService: boolean;
  canAddUser: boolean;
  canEditService: boolean;
  canDeleteService: boolean;
  canAddTemplate: boolean;
  canChangeServiceAssignment: boolean;
  canAddIncident: boolean;
  canEditIncident: boolean;
  canGetMessages: boolean;
  canGetUser: boolean;
  canEditUser: boolean;
  canDeleteUser: boolean;
  canGetTemplate: boolean;
  canDeleteTemplate: boolean;
  canEditTemplate: boolean;
  canGetServices: boolean;
  canCreateApiToken: boolean;

  constructor(roles: Array<'ADMINISTRATOR' | 'MANAGER' | 'SUBSCRIBER'>) {
    this.roles = roles;
    this.initRights();
  }

  private initRights() {
    this.canAddService = this.forAdmin();
    this.canEditService = this.forAdmin();
    this.canDeleteService = this.forAdmin();
    this.canAddUser = this.forAdmin();
    this.canAddTemplate = this.forAdmin();
    this.canEditUser = this.forAdmin();
    this.canDeleteUser = this.forAdmin();
    this.canGetTemplate = this.forAdminAndManager();
    this.canDeleteTemplate = this.forAdmin();
    this.canEditTemplate = this.forAdmin();
    this.canAddIncident = this.forAdminAndManager();
    this.canEditIncident = this.forAdminAndManager();
    this.canChangeServiceAssignment = this.forAdminAndManager();
    this.canGetMessages = this.forAdminAndManager();
    this.canGetUser = this.forAdminAndManager();
    this.canGetServices = this.forAdminAndManager();
    this.canCreateApiToken = this.forAdminAndManager();

  }

  private forAdmin(): boolean {
    return this.roles.includes('ADMINISTRATOR');
  }

  private forManager(): boolean {
    return this.roles.includes('MANAGER');
  }

  private forSubscriber(): boolean {
    return this.roles.includes('SUBSCRIBER');
  }

  private forAdminAndManager(): boolean {
    return this.roles.includes('ADMINISTRATOR') || this.roles.includes('MANAGER');
  }
}
