import {Component, OnInit} from '@angular/core';
import {AuthService} from '@services/auth.service';
import {VersionControllerService} from '@wu-api';
import {ConfigService} from '@services/config.service';

@Component({
  selector: 'wu-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'WhatsUp';
  feVersion: string;
  beVersion: string;

  constructor(private config: ConfigService, private authService: AuthService,
              private versionService: VersionControllerService) {
    authService.configureAuth();
  }

  ngOnInit(): void {
    this.setVersions();
  }

  setVersions() {
    this.versionService.getVersion()
    .subscribe(value => this.beVersion = value.version.toString());
    this.feVersion = this.config.configuration.version;
  }

}
