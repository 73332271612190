<div class="topbar-background">
  <div class="topbar-wording"></div>
  <div class="topbar-info">
    <div class="topbar-name">WhatsUp</div>
  </div>
</div>
<p-toast [style]="{marginTop: '110px'}"></p-toast>
<router-outlet></router-outlet>

<div class="footer ui-menu">
  {{'core.frontend_version'| translate}}: {{feVersion}} || {{'core.backend_version'| translate}}
  : {{beVersion}}
</div>
