/**
 * WhatsUp Application API
 * This is the WhatsUp RESTful service using OpenAPI 3.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * POJO that represents a request for registering devices.
 */
export interface PushDevice { 
    /**
     * This is the token, which should be generated through the Azure Notification Hub, to be able to send push notifications.
     */
    deviceToken: string;
    /**
     * Type of the device
     */
    deviceType: PushDevice.DeviceTypeEnum;
    /**
     * Unique ID of the device.
     */
    deviceId: string;
    /**
     * This is the name of the device, to have a better listing for management of the devices.
     */
    deviceName: string;
}
export namespace PushDevice {
    export type DeviceTypeEnum = 'IOS' | 'ANDROID';
    export const DeviceTypeEnum = {
        IOS: 'IOS' as DeviceTypeEnum,
        ANDROID: 'ANDROID' as DeviceTypeEnum
    };
}


