import {ErrorHandler, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WuErrorHandlerService extends ErrorHandler {

  handleError(error) {
    console.log(error);
  }
}
