import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {Configuration} from './configuration';
import {HttpClient} from '@angular/common/http';
import {APIS} from './api/api';
import {ConfigService} from '@services/config.service';
import {AuthService} from '@services/auth.service';
import {OAuthService} from 'angular-oauth2-oidc';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    APIS
  ]
})
export class WuApiModule {
  constructor(@Optional() @SkipSelf() parentModule: WuApiModule,
              @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('WuApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575');
    }
  }

  public static forRoot(configurationFactory: (config: ConfigService,
                                               auth: OAuthService) => Configuration): ModuleWithProviders {
    return {
      ngModule: WuApiModule,
      providers: [
        {
          provide: Configuration, useFactory: configurationFactory,
          deps: [ConfigService, OAuthService]
        }
      ]
    };
  }
}
