import {Injectable} from '@angular/core';
import {Rights} from '@shared/models/rights';
import {Role} from '@shared/models/Role';
import {MeResponse, UserControllerService} from '@wu-api';

@Injectable({
  providedIn: 'root'
})
export class RolesRightsService {
  public me: MeResponse;

  constructor(private userController: UserControllerService) {
  }

  /**
   * Done for calling api only at init and be sure getting a valid response.
   */
  getMe(): Promise<MeResponse> {
    return new Promise((resolve, reject) => {
      if (!this.me) {
        this.userController.getMe()
        .subscribe(value => {
          this.me = value;
          resolve(this.me);
        }, error => reject(error));
      } else {
        resolve(this.me);
      }
    });

  }

  getRights(): Promise<Rights> {
    return this.getMe()
    .then(value => {
      return new Rights(value.roles);
    });
  }
  getRoles(): Promise<Array<Role>> {
    return this.getMe().then(value =>  {
      return value.roles.map(v => Role[v]);
    });
  }
}
