/**
 * WhatsUp Application API
 * This is the WhatsUp RESTful service using OpenAPI 3.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a template, that should be edited.
 */
export interface TemplateEditRequest { 
    /**
     * Type of issue, this template is available for.
     */
    issueType?: TemplateEditRequest.IssueTypeEnum;
    /**
     * Body for the mail notification.
     */
    mailBody?: string;
    /**
     * Subject for the mail notification.
     */
    mailSubject?: string;
    /**
     * Body for the SMS notification.
     */
    smsContent?: string;
    /**
     * Body for the push notification.
     */
    pushContent?: string;
    /**
     * Service, this template is assigned to.
     */
    assignedService?: string;
    /**
     * This is the name of the template
     */
    name?: string;
}
export namespace TemplateEditRequest {
    export type IssueTypeEnum = 'FAILURE' | 'PARTIAL_FAILURE' | 'REMEDIED' | 'RELEASE' | 'ALARMED';
    export const IssueTypeEnum = {
        FAILURE: 'FAILURE' as IssueTypeEnum,
        PARTIALFAILURE: 'PARTIAL_FAILURE' as IssueTypeEnum,
        REMEDIED: 'REMEDIED' as IssueTypeEnum,
        RELEASE: 'RELEASE' as IssueTypeEnum,
        ALARMED: 'ALARMED' as IssueTypeEnum
    };
}


