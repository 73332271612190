import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import {OAuthService} from 'angular-oauth2-oidc';
import {Router} from '@angular/router';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;

  constructor(private oauthService: OAuthService, private router: Router) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
    .pipe(
        catchError((response: HttpResponse<any>) => {
          if (response && response.status === 401) {
            if (this.refreshTokenInProgress) {
              // If refreshTokenInProgress is true, we will wait until token is silently refreshed
              // which means the new token is ready and we can retry the request
              return this.oauthService.events.pipe(
                  filter(result => result.type === 'silently_refreshed'),
                  take(1),
                  switchMap(() => next.handle(this.getAuthRequest(req)))
              );
            } else {
              this.refreshTokenInProgress = true;

              return from(this.oauthService.refreshToken())
              .pipe(
                  switchMap(() => next.handle(this.getAuthRequest(req))),
                  catchError(error => {
                    this.initFlow();
                    return throwError(error);
                  }),
                  // When the call to silentRefresh completes we reset the refreshTokenInProgress to false
                  // for the next time the token needs to be refreshed
                  finalize(() => this.refreshTokenInProgress = false)
              );
            }
          }

          return throwError(response);
        })
    );
  }

  private getAuthRequest(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.oauthService.getAccessToken()
      }
    });
  }

  private initFlow(): void {
    const encodedUri = encodeURIComponent(this.router.url);
    this.oauthService.initLoginFlow(encodedUri);
  }
}

export const UNAUTHORIZED_INTERCEPTOR_PROVIDER = {
  provide: HTTP_INTERCEPTORS,
  useClass: UnauthorizedInterceptor,
  multi: true
};
