import {Injectable, OnDestroy} from '@angular/core';
import {AuthConfig, OAuthEvent, OAuthService} from 'angular-oauth2-oidc';
import {Observable} from 'rxjs';
import {RolesRightsService} from './roles-rights.service';
import {ConfigService} from '@services/config.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private authorized: Promise<any>;

  constructor(private oauthService: OAuthService, private config: ConfigService,
              private rolesRightsService: RolesRightsService, private router: Router) {
  }

  public configureAuth() {
    this.oauthService.configure(this.getAuthConfig());
    this.oauthService.setStorage(localStorage);
    this.authorized = this.oauthService.tryLoginCodeFlow()
    .then(() => {
      if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
        return this.loginBackend();
      } else {
        // to safe guard this from progressing through the calling promise,
        // resolve it when it directed to the sign up page
        return Promise.resolve(false);
      }
    })
    .then(isAuth => {
      if (isAuth) {
        const authState = this.oauthService.state;
        if (authState) {
          this.router.navigate([decodeURIComponent(authState)]);
        }
      }
      return Promise.resolve(isAuth);
    })
    .catch(reason => {
      console.error('Could not load IDP-Document');
      console.error(reason);
    });
    this.oauthService.setupAutomaticSilentRefresh();
  }

  public logout() {
    this.oauthService.logOut();
  }

  public login(redirectPath: string) {
    this.oauthService.initCodeFlow(redirectPath);
  }

  isAuthorized(): Promise<any> {
    return this.authorized;
  }

  public events(): Observable<OAuthEvent> {
    return this.oauthService.events;
  }

  ngOnDestroy(): void {
    this.oauthService.ngOnDestroy();
  }

  private loginBackend(): Promise<boolean> {
    return new Promise((resolve, reject) => {
          this.rolesRightsService.getMe()
          .then(me => {
            if (me.roles.includes('MANAGER') || me.roles.includes('ADMINISTRATOR')) {
              return resolve(true);
            } else {
              return reject('No role for user');
            }
          })
          .catch(reason => {
            return reject('Error in /me');
          });
        }
    );
  }

  private getAuthConfig(): AuthConfig {
    const origin = window.location.origin;
    return {

      // Url of the Identity Provider
      issuer: this.config.getAuthUrl(),

      // URL of the SPA to redirect the user to after login
      redirectUri: origin,

      postLogoutRedirectUri: origin,
      // The SPA's id. The SPA is registered with this id at the auth-server
      clientId: this.config.getClientId(),

      // set the scope for the permissions the client should request
      // The first three are defined by OIDC. The 4th is a usecase-specific one
      scope: 'openid offline_access',

      responseType: 'code',

      // we need to this, in order to be able to use PKI authentication in Firefox. Use "discover"
      // does not work, because of SSL reuse in Firefox
      tokenEndpoint: this.config.getAuthUrl() + '/protocol/openid-connect/token',
      loginUrl: this.config.getAuthUrl() + '/protocol/openid-connect/auth',
      logoutUrl: this.config.getAuthUrl() + '/protocol/openid-connect/logout',
    };
  }
}
