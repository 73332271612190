export * from './incidentController.service';
import { IncidentControllerService } from './incidentController.service';
export * from './messageController.service';
import { MessageControllerService } from './messageController.service';
export * from './serviceController.service';
import { ServiceControllerService } from './serviceController.service';
export * from './templateController.service';
import { TemplateControllerService } from './templateController.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export * from './versionController.service';
import { VersionControllerService } from './versionController.service';
export const APIS = [IncidentControllerService, MessageControllerService, ServiceControllerService, TemplateControllerService, UserControllerService, VersionControllerService];
