/**
 * WhatsUp Application API
 * This is the WhatsUp RESTful service using OpenAPI 3.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ErrorResponse } from '../model/models';
import { MessageCreateRequest } from '../model/models';
import { PageMessageResponse } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateMessageRequestParams {
    messageCreateRequest?: MessageCreateRequest;
}

export interface GetMessagesRequestParams {
    /** This parameter can be used for filtering the response for the ids of the messages. This parameter excepts a list of UUIDs. */
    messageIds?: Array<string>;
    /** Parameter for filtering the users by mail subject. Parameter will be handled as \&#39;contains\&#39; */
    mailSubject?: string;
    /** Parameter for filtering the users by mail body. Parameter will be handled as \&#39;contains\&#39; */
    mailBody?: string;
    /** Parameter for filtering the users by sms content. Parameter will be handled as \&#39;contains\&#39; */
    smsContent?: string;
    /** Parameter for filtering the users by push content. Parameter will be handled as \&#39;contains\&#39; */
    pushContent?: string;
    /** Parameter for filtering the users by first name. Parameter will be handled as \&#39;contains\&#39; */
    firstName?: string;
    /** Parameter for filtering the users by last name. Parameter will be handled as \&#39;contains\&#39; */
    lastName?: string;
    /** If set to true, then  */
    allMessageTypes?: boolean;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
    sort?: Array<string>;
}


@Injectable()
export class MessageControllerService {

    protected basePath = 'https://api.develop.whatsup.cloud.cstx-mi.de';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Creates a new message.
     * This method is only permitted for users with the application role &lt;strong&gt;ADMINISTRATOR&lt;/strong&gt; or &lt;strong&gt;MANAGER&lt;/strong&gt;.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMessage(requestParameters: CreateMessageRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public createMessage(requestParameters: CreateMessageRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public createMessage(requestParameters: CreateMessageRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public createMessage(requestParameters: CreateMessageRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const messageCreateRequest = requestParameters.messageCreateRequest;

        let headers = this.defaultHeaders;

        // authentication (bearer-jwt) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/messages`,
            messageCreateRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lists all messages.
     * With this endpoint, you can retrieve all messages from the system. The endpoint provides filtering mechanism. All filter are combined with an AND operator. This method is only permitted for users with the application roles &lt;strong&gt;ADMINISTRATOR&lt;/strong&gt;, &lt;strong&gt;MANAGER&lt;/strong&gt; or &lt;strong&gt;SUBSCRIBE&lt;/strong&gt;. Users with role &lt;strong&gt;MANAGER&lt;/strong&gt; must be in the role of a manager of the service. If a &lt;strong&gt;SUBSCRIBER&lt;/strong&gt; calls this endpoint, then only manual created messages will be returned, unless the parameter &lt;i&gt;allMessageTypes&lt;/i&gt; is set to &lt;i&gt;true&lt;/i&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMessages(requestParameters: GetMessagesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PageMessageResponse>;
    public getMessages(requestParameters: GetMessagesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PageMessageResponse>>;
    public getMessages(requestParameters: GetMessagesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PageMessageResponse>>;
    public getMessages(requestParameters: GetMessagesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const messageIds = requestParameters.messageIds;
        const mailSubject = requestParameters.mailSubject;
        const mailBody = requestParameters.mailBody;
        const smsContent = requestParameters.smsContent;
        const pushContent = requestParameters.pushContent;
        const firstName = requestParameters.firstName;
        const lastName = requestParameters.lastName;
        const allMessageTypes = requestParameters.allMessageTypes;
        const page = requestParameters.page;
        const size = requestParameters.size;
        const sort = requestParameters.sort;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (messageIds) {
            messageIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'messageIds');
            })
        }
        if (mailSubject !== undefined && mailSubject !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>mailSubject, 'mailSubject');
        }
        if (mailBody !== undefined && mailBody !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>mailBody, 'mailBody');
        }
        if (smsContent !== undefined && smsContent !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>smsContent, 'smsContent');
        }
        if (pushContent !== undefined && pushContent !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pushContent, 'pushContent');
        }
        if (firstName !== undefined && firstName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>firstName, 'firstName');
        }
        if (lastName !== undefined && lastName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastName, 'lastName');
        }
        if (allMessageTypes !== undefined && allMessageTypes !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>allMessageTypes, 'allMessageTypes');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>size, 'size');
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sort');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-jwt) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageMessageResponse>(`${this.configuration.basePath}/api/v1/messages`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
