/**
 * WhatsUp Application API
 * This is the WhatsUp RESTful service using OpenAPI 3.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * POJO that represents incident types.
 */
export interface IncidentTypeResponse { 
    types: Array<IncidentTypeResponse.TypesEnum>;
}
export namespace IncidentTypeResponse {
    export type TypesEnum = 'FAILURE' | 'PARTIAL_FAILURE' | 'REMEDIED' | 'RELEASE' | 'ALARMED';
    export const TypesEnum = {
        FAILURE: 'FAILURE' as TypesEnum,
        PARTIALFAILURE: 'PARTIAL_FAILURE' as TypesEnum,
        REMEDIED: 'REMEDIED' as TypesEnum,
        RELEASE: 'RELEASE' as TypesEnum,
        ALARMED: 'ALARMED' as TypesEnum
    };
}


