import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@services/auth.service';
import {RolesRightsService} from '@services/roles-rights.service';
import {Role} from '@shared/models/Role';
import {filter, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private previousUrl: string;

  constructor(public auth: AuthService, public router: Router, private roles: RolesRightsService) {
    this.soreLastRoute();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const needs: Array<Role> = route.data.roles;
    return new Promise(resolve => {
      this.auth.isAuthorized()
      .then(isAuth => {
        if (isAuth) {
          this.hasRole(needs, resolve);
        } else {
          resolve(false);
          this.router.navigate(['login']);
        }
      })
      .catch(reason => {
        resolve(false);
      });
    });
  }

  private hasRole(needs: Array<Role>, resolve) {
    if (!needs) {
      resolve(true);
    } else {
      const hasRoleP = this.roles.getRoles()
      .then(roles => roles.filter(v => needs.includes(v)).length > 0);
      hasRoleP.then(hasRole => {
        if (hasRole) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, reason => {
        resolve(false);
      });
    }
  }

  private soreLastRoute() {
    this.router.events
    .pipe(
        filter(event => event instanceof NavigationEnd),
        map(e => e as NavigationEnd),
    )
    .subscribe(e => {
      this.previousUrl = e.url;
    });
  }
}
