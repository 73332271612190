/**
 * WhatsUp Application API
 * This is the WhatsUp RESTful service using OpenAPI 3.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceRelation } from './serviceRelation';


/**
 * Represents a user, that is able to login into the application.
 */
export interface UserResponse { 
    roles?: Array<UserResponse.RolesEnum>;
    serviceRelations?: Array<ServiceRelation>;
    id: string;
    /**
     * Mobile phone number of the user.
     */
    mobilePhone: string;
    /**
     * E-Mail Address of the user.
     */
    emailAddress: string;
    /**
     * This is the VW-User-ID of the user
     */
    userId: string;
    /**
     * E-Mail Address of the user.
     */
    costCenter: string;
    /**
     * Department of the user.
     */
    department: string;
    /**
     * First name of the user.
     */
    firstName: string;
    /**
     * Last name of the user.
     */
    lastName: string;
    /**
     * Phone number of the user.
     */
    phone: string;
}
export namespace UserResponse {
    export type RolesEnum = 'ADMINISTRATOR' | 'MANAGER' | 'SUBSCRIBER';
    export const RolesEnum = {
        ADMINISTRATOR: 'ADMINISTRATOR' as RolesEnum,
        MANAGER: 'MANAGER' as RolesEnum,
        SUBSCRIBER: 'SUBSCRIBER' as RolesEnum
    };
}


