/**
 * WhatsUp Application API
 * This is the WhatsUp RESTful service using OpenAPI 3.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a the user data of the authenticated users, which should be changed.
 */
export interface MeEditRequest { 
    /**
     * Mobile phone number of the user.
     */
    mobilePhone?: string;
    /**
     * E-Mail Address of the user.
     */
    emailAddress?: string;
    /**
     * E-Mail Address of the user.
     */
    costCenter?: string;
    /**
     * Department of the user.
     */
    department?: string;
    /**
     * First name of the user.
     */
    firstName?: string;
    /**
     * Last name of the user.
     */
    lastName?: string;
    /**
     * Phone number of the user.
     */
    phone?: string;
    notificationTypes?: Array<MeEditRequest.NotificationTypesEnum>;
}
export namespace MeEditRequest {
    export type NotificationTypesEnum = 'EMAIL' | 'SMS' | 'PUSH';
    export const NotificationTypesEnum = {
        EMAIL: 'EMAIL' as NotificationTypesEnum,
        SMS: 'SMS' as NotificationTypesEnum,
        PUSH: 'PUSH' as NotificationTypesEnum
    };
}


