import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {CoreModule} from '@core/core.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {TranslateHttpPropertiesLoader} from 'ngx-translate-properties-loader2';
import {CookieService} from 'ngx-cookie-service';
import {AppRoutingModule} from './app-routing.module';
import {MessageModule, MessageService, MessagesModule, ToastModule} from 'primeng';
import {ConfigService} from '@services/config.service';
import {UNAUTHORIZED_INTERCEPTOR_PROVIDER, UnauthorizedInterceptor} from '@core/interceptor/http-interceptor';

export function initApp(config: ConfigService) {
  return () => config.initializeApp();
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpPropertiesLoader(http, './assets/i18n/messages_', '.properties');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ToastModule,
    MessagesModule,
    MessageModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [ConfigService]
    },
    UNAUTHORIZED_INTERCEPTOR_PROVIDER,
    CookieService,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faPlus);
  }
}
