/**
 * WhatsUp Application API
 * This is the WhatsUp RESTful service using OpenAPI 3.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * POJO that represents a service.
 */
export interface ServiceResponse { 
    managerUserIds?: Array<string>;
    subscriberUserIds?: Array<string>;
    /**
     * Name of the service.
     */
    name: string;
    /**
     * ID of the service.
     */
    id: string;
    /**
     * State of the service.
     */
    state: ServiceResponse.StateEnum;
    /**
     * A short description of the service
     */
    description: string;
    /**
     * Component of the service
     */
    component: string;
    /**
     * List of components of the service
     */
    components?: Array<string>;
    /**
     * Is the service one of the important services?
     */
    important?: boolean;
    /**
     * A private note for the service
     */
    privateNote?: string;
}
export namespace ServiceResponse {
    export type StateEnum = 'FAILURE' | 'PARTIAL_FAILURE' | 'REMEDIED' | 'RELEASE' | 'ALARMED';
    export const StateEnum = {
        FAILURE: 'FAILURE' as StateEnum,
        PARTIALFAILURE: 'PARTIAL_FAILURE' as StateEnum,
        REMEDIED: 'REMEDIED' as StateEnum,
        RELEASE: 'RELEASE' as StateEnum,
        ALARMED: 'ALARMED' as StateEnum
    };
}


