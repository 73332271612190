import {ErrorHandler, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {OAuthModule, OAuthService} from 'angular-oauth2-oidc';
import {Configuration, WuApiModule} from '@wu-api';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ConfigService} from '@services/config.service';
import {WuErrorHandlerService} from '@services/wu-error-handler.service';

const configurationFactory = (config: ConfigService, auth: OAuthService) => {
  const c = new Configuration();
  c.basePath = config.getApiUrl();
  c.accessToken = () => auth.getAccessToken()
  return c;
};

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot(),
    WuApiModule.forRoot(configurationFactory)
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: WuErrorHandlerService
    }
  ]
})
export class CoreModule {
}
