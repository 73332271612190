export const environment = {
  production: true,
  envConfigUrl: '/config.json',
  apiUrlFallback: 'https://whatsup-backend-develop.azurewebsites.net',
  authUrlFallback: 'https://idp.ci.cloud.cstx.de/auth/realms/cstx-develop',
  clientIdFallback: 'whatsup',
  clientSecret: 'b98d1986-3f9c-42fe-8a00-205e0cfe2ae7',
  version: 'local',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
