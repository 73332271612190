import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(public auth: AuthService, public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(resolve => {
      this.auth.isAuthorized()
      .then(value => {
        if (value) {
          this.router.navigate(['home']);
          resolve(false);
        } else {
          resolve(true);
        }
      })
      .catch(reason => {
        this.auth.logout();
        resolve(true);
      });
    });
  }
}
